// @flow

import React from 'react'
import styled from '@emotion/styled'
import { colors, media } from '@toggl/style'
import { Hero, text, Button } from '@toggl/ui'

import IndexLayout from 'layouts'
import LayoutTrack from 'components/LayoutTrack'
import heroBg from 'images/uploads/homepage-hero-bg.jpg'
import videoSrc from 'images/uploads/bg-video.mp4'

import type { PageProps } from 'gatsby'

declare var process: WebProcess

const MobileHeroComponent = ({ children }) => {
  return (
    <Hero.Wrapper bgImage={heroBg} bgVideo={videoSrc}>
      {children}

      <StyledWrapper>
        <Hero.Title centered>You're using a mobile device</Hero.Title>
        <SmallTitle>
          The Toggl webapp works best with your desktop or tablet. To track time
          on your mobile phone, download our iOS/Android app!
        </SmallTitle>
        <SignupCta>
          <span>
            You can continue to view our webapp with your mobile device if you
            wish... but beware, things will look a little funny.
          </span>
          <a href={process.env.GATSBY_TRACK_APP_URL}>
            <Button.Primary>Continue to Toggl Track...</Button.Primary>
          </a>
        </SignupCta>
      </StyledWrapper>
    </Hero.Wrapper>
  )
}

const DesktopHeroComponent = ({ children }) => {
  return (
    <Hero.Wrapper bgImage={heroBg} bgVideo={videoSrc}>
      {children}

      <StyledWrapper>
        <Hero.Title centered>You are using an outdated browser.</Hero.Title>
        <SmallTitle>
          We currently only support modern browsers such as Chrome, Firefox,
          Safari and Edge. <br />
          For more information, check out our Knowledge Base article on browser
          support. If you need further assistance, our Support team is here to
          help.
        </SmallTitle>
        <SignupCta>
          <span>
            You can continue to view our webapp with your current browser if you
            wish... but beware, things will look a little funny.
          </span>
          <a href={process.env.GATSBY_TRACK_APP_URL}>
            <Button.Primary>Continue to Toggl Track...</Button.Primary>
          </a>
        </SignupCta>
      </StyledWrapper>
    </Hero.Wrapper>
  )
}

const DeviceUnsupportedPage = ({ location }: PageProps<null, null>) => {
  const device = new URLSearchParams(location.search).get('device')
  const HeroComponent =
    device !== 'browser' ? MobileHeroComponent : DesktopHeroComponent

  return (
    <IndexLayout>
      <LayoutTrack Hero={HeroComponent}>
        <Root></Root>
      </LayoutTrack>
    </IndexLayout>
  )
}

const StyledWrapper = styled(Hero.ContentWrapper)`
  ${media.mq[1]} {
    padding: 76px 20px 168px 20px;
  }
`
const Root = styled.div`
  padding-bottom: 130px;
`
const SignupCta = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  span {
    ${text.paragraph2};
    color: ${colors.almostWhite};
    margin-bottom: 30px;
  }
`
const SmallTitle = styled.div`
  ${text.paragraph2};
  color: ${colors.almostWhite};
  text-align: center;
  margin-bottom: 15px;

  ${media.mq[1]} {
    margin-bottom: 30px;
  }
`

export default DeviceUnsupportedPage
